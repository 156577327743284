@use "../var" with ( $include-breakpointLG: false );@use '..' as able;

.able-Switch {
  @include able.Switch;
}

.able-Switch--Compact--hasTopDivider {
  @include able.Switch($hasTopDivider: true);
}

.able-Switch--Comfortable {
  @include able.Switch(Comfortable);
}
