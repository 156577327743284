@use "../var" with ( $include-breakpointLG: false );@use '..' as able;

.able-MessageInline {
  @each $variant in able.$MessageInline-variants {
    &--#{$variant} {
      @include able.MessageInline($variant);

      &--On-Surface {
        @include able.MessageInline($variant, $on-surface: true);
      }
    }
  }
}
