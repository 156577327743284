.modal-dialog {
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  margin-left: auto !important;
  margin-right: auto !important;
}
.ReactModal__Content {
  margin-top: 150px !important;
  display: inherit !important;
  min-height: auto !important;
}
.ReactModal__Content .modal-body {
  padding-bottom: 20px !important;
  padding-left: 20px !important;
}
.ReactModal__Content .modal-header-no-border .header {
  padding-left: 24px;
  color: #141414;
  font-family: Montserrat;
}
.ReactModal__Content .modal-header-no-border .subHeader {
  padding-left: 24px;
  padding-bottom: 24px;
  padding-top: 14px;
  height: 22px;
}
.ReactModal__Content .modal-header-no-border .subPara {
  padding-left: 24px;
}
.ReactModal__Content .modal-footer {
  padding: 0.3rem !important;
}
.ReactModal__Content .modal-backdrop.in {
  opacity: 1;
}
.time-out-image {
  margin-left: 24px;
  margin-top: 32px;
  margin-bottom: 15px;
  width: 56px;
  height: 56px;
}
.close-svg {
  float: right;
  stroke: #b4aaaa;
  margin-right: 22px;
  margin-top: 22px;
}
.close-svg g {
  fill: var(--colour-primary);
}
@media only screen and (min-width: 48rem) {
  .modal-header-no-border .header {
    padding-left: 32px !important;
  }
  .modal-header-no-border .subHeader {
    padding-left: 32px !important;
  }
  .modal-header-no-border .subPara {
    padding-left: 32px !important;
    padding-right: 24px;
    margin-bottom: 40px;
  }
  .time-out-image {
    margin-left: 32px;
  }
}
