.ReactModal__Content .modal-content {
  top: 2px !important;
  border-radius: 0 !important;
  border: none !important;
}
.ppv-modal {
  width: 559px !important;
  margin-top: 30px !important;
}
@media only screen and (max-width: 767px) {
  .ppv-modal .modal-content {
    height: auto;
    border: none;
    background-color: #f8f8f8 !important;
  }
}
.ppv-modal .modal-header-no-border .close {
  margin-top: 5px;
}
@media only screen and (max-width: 767px) {
  .ppv-modal .modal-header-no-border .close {
    margin-top: -5px !important;
  }
}
.ppv-modal .btn.primary {
  float: left !important;
  margin-left: 20px !important;
  margin-top: 15px !important;
}
@media only screen and (max-width: 767px) {
  .ppv-modal .btn.primary {
    margin-left: 0px !important;
  }
}
.ppv-modal .providerNumber {
  margin-top: 10px !important;
}
.ppv-modal .portNumber {
  margin-left: 20px !important;
}
.ppv-modal .nextButton {
  left: 10px !important;
}
@media only screen and (max-width: 767px) {
  .ppv-modal .nextButton {
    left: 0px !important;
    width: 85% !important;
    margin-top: 30px !important;
  }
}
.ppv-modal .modal-body-porting {
  height: auto !important;
  margin-bottom: 16px;
  padding: 0 30px;
  background-color: #f8f8f8 !important;
}
@media only screen and (max-width: 767px) {
  .ppv-modal .modal-body-porting {
    padding: 0 20px;
  }
}
.ppv-modal .modal-body-porting .button-as-link {
  margin-top: -3px;
  color: #FF4300;
  padding-left: 5px !important;
  margin-left: 0px !important;
}
.ppv-modal form {
  margin-top: 8px !important;
}
.ppv-modal .porting-lock-image {
  height: 56px;
  width: 56px;
  margin-top: 25px;
  margin-left: 30px;
  margin-bottom: 22px;
}
@media only screen and (max-width: 767px) {
  .ppv-modal .porting-lock-image {
    margin-top: 15px;
    margin-left: 15px;
  }
}
.radio-toolbar {
  margin: 10px;
  margin-top: -10px;
  width: 50% !important;
}
@media only screen and (max-width: 767px) {
  .radio-toolbar {
    width: 85% !important;
    margin: 0px;
  }
}
.radio-toolbar input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}
.radio-toolbar label {
  display: inline-block;
  height: 48px;
  width: 210px !important;
}
@media only screen and (max-width: 767px) {
  .radio-toolbar label {
    width: 100% !important;
  }
}
.ppv-header {
  height: 110px;
  background-color: #f8f8f8 !important;
}
@media only screen and (max-width: 767px) {
  .ppv-header {
    height: auto !important;
  }
}
.ppv-header-sms {
  height: 60px;
}
@media only screen and (max-width: 767px) {
  .ppv-header-sms {
    height: auto !important;
  }
}
.ppv-message-header {
  width: 56px;
  height: 56px;
  margin-top: 25px;
  margin-left: 12px;
}
.ppv-message-header .porting-error-image {
  width: 56px;
  height: 56px;
  margin-left: 18px;
}
.ppv-message-header.porting-error-header .modal-close-btn {
  position: absolute;
  top: 0;
  right: 0;
}
@media only screen and (max-width: 767px) {
  .ppv-message-header .porting-error-image {
    width: 50px;
    height: 50px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 10px;
  }
}
.ppv-heading {
  margin-left: 30px !important;
  font-size: 26px !important;
  margin-top: 78px !important;
}
@media only screen and (max-width: 767px) {
  .ppv-heading {
    margin-top: 35px !important;
    margin-left: 0px !important;
    line-height: 1;
    font-size: 20px !important;
  }
}
.yourPhoneNumber {
  color: #333333;
  font-size: 28px !important;
  line-height: 32px;
}
.ppv-p {
  text-align: left !important;
  margin-left: 30px !important;
  font-family: "Akkurat-light", "Helvetica Neue", "Arial Narrow", Helvetica, Arial, sans-serif !important;
  margin-bottom: 0px !important;
}
@media only screen and (max-width: 767px) {
  .ppv-p {
    margin-left: 0px !important;
    text-align: inherit !important;
    line-height: 1.4 !important;
    margin-bottom: 10px !important;
  }
}
.new-number {
  width: 60%;
  margin-top: 10px;
}
@media only screen and (max-width: 767px) {
  .new-number {
    width: 85%;
    margin-top: 0;
  }
}
p.instructions {
  margin-left: 30px !important;
  text-align: inherit !important;
}
@media only screen and (max-width: 767px) {
  p.instructions {
    margin-left: 0px !important;
    margin-bottom: 5px !important;
  }
}
.transfer-number-body {
  width: 95%;
}
.transfer-number-body .verify-number-subheading {
  width: 85%;
}
.transfer-number-body .alert-error {
  margin-top: 24px;
  width: 70%;
}
@media only screen and (max-width: 767px) {
  .transfer-number-body {
    width: 100%;
  }
  .transfer-number-body .verify-number-subheading {
    width: 100%;
  }
  .transfer-number-body .alert-error {
    width: 100%;
    margin-top: 24px;
  }
}
.ppv-form-group .form-element {
  margin-top: 0px !important;
}
@media only screen and (max-width: 767px) {
  .ppv-form-group .form-element {
    margin-left: 0px !important;
  }
}
.ppv-form-group .form-element label {
  font: 700 1rem/1.4 Montserrat, Helvetica Neue, Helvetica, Arial, sans-serif;
}
.ppv-form-group .form-element input {
  width: 70%;
  padding-right: 10px;
}
.ppv-form-group .form-element .td-icon {
  left: -25px;
  float: none;
  top: 0px;
}
@media only screen and (max-width: 767px) {
  .ppv-form-group .form-element .td-icon {
    left: -25px;
    float: right;
    top: -40px;
  }
}
.ppv-form-group p.instructions {
  margin-left: 0px !important;
  margin-bottom: 10px !important;
  display: inline-flex;
}
.small {
  font-size: 12px !important;
  margin-top: -15px !important;
  margin-bottom: 0px !important;
}
.otp-div .form-element {
  min-height: unset !important;
}
.otp-div .bold {
  margin-top: 20px !important;
  margin-left: 10px !important;
  text-align: inherit !important;
}
@media only screen and (max-width: 767px) {
  .otp-div .bold {
    margin-left: 0px !important;
  }
}
.otp-div p.instructions {
  margin-left: 10px !important;
  padding-top: 15px;
}
@media only screen and (max-width: 767px) {
  .otp-div p.instructions {
    margin-left: 0px !important;
  }
}
@media only screen and (max-width: 767px) {
  .otp-div div {
    margin-left: 0px;
  }
}
.otp-div .error {
  border: 1px solid red !important;
}
.resend-sms-code .ppa-message.warning {
  margin-left: 30px !important;
  margin-top: 20px !important;
}
@media only screen and (max-width: 767px) {
  .resend-sms-code .ppa-message.warning {
    margin-left: 0px !important;
  }
}
.sms-check {
  text-align: center;
  width: 325px;
  margin: auto;
  margin-top: 30px;
}
.sms-check p.center-align {
  font-size: 20px !important;
}
.sms-check h3 {
  margin-top: 30px;
}
.sms-check .continue-button {
  width: 250px !important;
  margin-top: 10px !important;
  text-align: center !important;
}
.sms-check .icon-info {
  margin-bottom: 40px;
}
.sms-check .icon-info .td-icon {
  font-size: 62px;
}
@media only screen and (max-width: 767px) {
  .sms-check {
    width: 295px;
    margin-top: 0px;
  }
}
@media only screen and (max-width: 767px) {
  .sms-check-div {
    margin-bottom: 140px;
  }
}
.sms-code-note {
  padding-top: 15px;
}
.error-check .error-para {
  margin-top: -20px;
}
@media only screen and (max-width: 767px) {
  .error-check .error-para {
    margin-top: -15px;
  }
}
.error-check p {
  text-align: unset !important;
}
.code-expire-error {
  margin-top: 20px;
}
.code-expire-error .error-para {
  margin-top: -20px;
}
@media only screen and (max-width: 767px) {
  .code-expire-error .error-para {
    margin-top: -15px;
  }
}
.code-expire-error .button-box {
  margin-bottom: 32px;
}
.code-expire-error .button-box .continue-button {
  float: none !important;
  width: 205px !important;
  margin-top: 10px !important;
  text-align: center !important;
  left: -10px;
}
@media only screen and (max-width: 767px) {
  .code-expire-error .button-box .continue-button {
    width: 240px !important;
    left: -22px;
  }
}
@media only screen and (max-width: 767px) {
  .code-expire-error {
    width: 240px;
    margin-top: 0px;
    margin: auto;
  }
}
.not-allowed {
  cursor: not-allowed !important;
}
.ppv-note {
  margin-top: -5px !important;
}
.resend-sms {
  margin-top: 100px;
  height: 250px;
}
.resend-sms svg path {
  stroke: #FF4300 !important;
}
@media only screen and (max-width: 767px) {
  .resend-sms {
    width: 240px;
    margin-top: 60px !important;
    margin: auto;
    margin-bottom: 100px;
    height: unset !important;
  }
}
.resend-sms .resend-sms-para {
  width: 250px;
  margin: auto;
  text-align: center;
  margin-top: 40px;
}
.resend-sms .mt-spinner {
  margin: auto;
}
.mb-ppa {
  margin-bottom: 32px;
}
.ppv-modal a:link {
  color: #FF4300 !important;
}
.mb-button {
  margin-bottom: 24px !important;
}
@media screen and (min-width: 48rem) {
  .porting-verify-your-number,
  .porting-verify-sms-code {
    width: 70%;
  }
}
.form-element .text-grey-dark {
  margin-top: 10px !important;
}
.inprogress-err-img {
  height: 42.92px;
  width: 42.92px;
  margin-bottom: 10px;
}
.inprogress-err-heading {
  color: #414141;
  font-size: 32px;
  font-weight: bold;
}
.inprogress-err-para {
  color: #414141;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0.15px;
}
@media only screen and (max-width: 767px) {
  .para1 {
    margin-top: 20% !important;
  }
}
.modal-close-btn {
  float: right;
}
.modal-close-btn path,
.modal-close-btn use,
.modal-close-btn rect {
  fill: #282828 !important;
}
