.form-element input {
  width: 100%;
  background-color: #f8f8f8 !important;
  border: 0;
  border-bottom: 1px solid #414141;
  padding-bottom: 10px;
  padding-right: 40px;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  font-family: "Helvetica Neue", Arial, sans-serif;
  margin-bottom: 10px;
}
.form-element input:focus,
.form-element input:active {
  border-bottom: 1.9px solid #FF7F3F;
  outline: none;
}
@media only screen and (max-width: 767px) {
  .form-element input {
    width: 100% !important;
  }
}
.form-element input.onlyLabel {
  display: none;
}
.form-element input.has-error:focus {
  border-bottom: 2px solid #B32034 !important;
}
.form-element input:disabled {
  opacity: 1;
  background-color: #E9E9E9;
}
.form-element .number-tick {
  position: absolute;
  right: 2px;
  top: 34px;
}
.form-element .number-tick path,
.form-element .number-tick use,
.form-element .number-tick rect {
  fill: green !important;
}
.form-element .tooltip-icon {
  float: none;
  left: 5px;
  right: 0px;
  top: 0px !important;
  font-size: 18px;
  color: #0064D2 !important;
}
.form-element .tooltip-icon.icon-information {
  margin-right: 5px;
}
.form-element .has-error {
  border-bottom: 1px solid #B32034 !important;
}
.form-element .error-msg + .password-eye {
  bottom: 42px !important;
}
.form-element .password-eye {
  position: absolute;
  height: 16px;
  color: #f8f8f8 !important;
  padding: 4px 8px;
  right: 40px;
  width: 20px !important;
  float: right;
  outline: none;
  bottom: 25px;
}
.form-element .password-eye.show-password {
  background-image: url("../../../assets/img/telstra/forms/show_password.png");
}
.form-element .password-eye.hide-password {
  background-image: url("../../../assets/img/telstra/forms/hide_password.png");
}
.form-element .text-grey-dark {
  font-weight: bold;
}
.form-element input:-webkit-autofill,
.form-element input:-webkit-autofill:hover,
.form-element input:-webkit-autofill:focus textarea:-webkit-autofill,
.form-element textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
.form-element select:-webkit-autofill,
.form-element select:-webkit-autofill:hover,
.form-element select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #f8f8f8 inset !important;
}
