@font-face {
  font-family: "Montserrat";
  font-display: swap;
  src: url(assets/fonts/Montserrat/Montserrat-Regular.otf);
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat-Bold";
  font-display: swap;
  src: url(assets/fonts/Montserrat/Montserrat-Bold.otf);
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat-Regular";
  font-display: swap;
  src: url(assets/fonts/Montserrat/Montserrat-Light.otf);
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat-ExtraBold";
  font-display: swap;
  src: url(assets/fonts/Montserrat/Montserrat-ExtraBold.otf);
  font-weight: 800;
  font-style: normal;
}
.boost-ppa .mt-spinner svg path {
  stroke: #FF4300 !important;
}
.ppv-modal .mt-sheet__toolbar:before {
  background: none !important;
}
.boost-ppa .mt-alert,
.boost-ppa .mt-card {
  background-color: var(--white);
}
.mt-alert--default svg g g {
  stroke: #FF4300 !important;
}
.boost-color-svg path,
.boost-color-svg use,
.boost-color-svg rect {
  fill: #FF4300 !important;
}
