.refresh_spinner {
  display: block;
  height: 40px;
  width: 40px !important;
  padding: 8px;
  margin: 0 auto;
  border-radius: 100%;
}
.refresh_spinner .loader {
  position: relative;
  margin: 0 auto;
  width: 64px;
  height: 64px;
  top: -50%;
  left: -50%;
  transform: translate(-4px, -4px);
}
.refresh_spinner .loader .circular {
  animation: rotate 2s linear infinite;
  height: 64px;
  position: relative;
  width: 64px;
}
.refresh_spinner .loader .circular .path {
  animation: dash 1.5s ease-in-out infinite;
  cx: 32px;
  cy: 32px;
  fill: none;
  r: 12.8px;
  stroke: #FF4300;
  stroke-dasharray: 1%,200%;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  stroke-width: 2;
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1%, 200%;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89%,200%;
    stroke-dashoffset: -35%;
  }
  100% {
    stroke-dasharray: 89%,200%;
    stroke-dashoffset: -124%;
  }
}
.recharge_spinner,
.payment_spinner {
  padding: 0;
  margin-top: 60px;
}
.recharge_spinner .loader,
.payment_spinner .loader {
  position: relative;
  margin: 0 auto;
  width: 112px;
  height: 112px;
  top: -50%;
  left: -50%;
  transform: translate(-4px, -4px);
}
.recharge_spinner .loader .circular,
.payment_spinner .loader .circular {
  animation: rotate 2s linear infinite;
  height: 112px;
  position: relative;
  width: 112px;
}
.recharge_spinner .loader .circular .path,
.payment_spinner .loader .circular .path {
  animation: dash 1.5s ease-in-out infinite;
  cx: 56px;
  cy: 56px;
  fill: none;
  r: 22.4px;
  stroke: #FF4300;
  stroke-dasharray: 1%,200%;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  stroke-width: 3;
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1%, 200%;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89%,200%;
    stroke-dashoffset: -35%;
  }
  100% {
    stroke-dasharray: 89%,200%;
    stroke-dashoffset: -124%;
  }
}
.SpinningButton-container button[class*=medium-emphasis] .loader {
  position: relative;
  margin: 0 auto;
  width: 48px;
  height: 48px;
  top: -50%;
  left: -50%;
  transform: translate(-4px, -4px);
}
.SpinningButton-container button[class*=medium-emphasis] .loader .circular {
  animation: rotate 2s linear infinite;
  height: 48px;
  position: relative;
  width: 48px;
}
.SpinningButton-container button[class*=medium-emphasis] .loader .circular .path {
  animation: dash 1.5s ease-in-out infinite;
  cx: 24px;
  cy: 24px;
  fill: none;
  r: 9.6px;
  stroke: #FF4300;
  stroke-dasharray: 1%,200%;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  stroke-width: 2;
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1%, 200%;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89%,200%;
    stroke-dashoffset: -35%;
  }
  100% {
    stroke-dasharray: 89%,200%;
    stroke-dashoffset: -124%;
  }
}
.SpinningButton-container button[class*=high-emphasis] .loader {
  position: relative;
  margin: 0 auto;
  width: 48px;
  height: 48px;
  top: -50%;
  left: -50%;
  transform: translate(-4px, -4px);
}
.SpinningButton-container button[class*=high-emphasis] .loader .circular {
  animation: rotate 2s linear infinite;
  height: 48px;
  position: relative;
  width: 48px;
}
.SpinningButton-container button[class*=high-emphasis] .loader .circular .path {
  animation: dash 1.5s ease-in-out infinite;
  cx: 24px;
  cy: 24px;
  fill: none;
  r: 9.6px;
  stroke: #FFF;
  stroke-dasharray: 1%,200%;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  stroke-width: 2;
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1%, 200%;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89%,200%;
    stroke-dashoffset: -35%;
  }
  100% {
    stroke-dasharray: 89%,200%;
    stroke-dashoffset: -124%;
  }
}
