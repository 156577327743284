@use "../var" with ( $include-breakpointLG: false );@use '..' as able;

.able-ModalContent {
  @include able.ModalContent;

  &--Compact {
    @include able.ModalContent(Compact);
  }

  &--Comfortable {
    @include able.ModalContent(Comfortable);
  }

  &--Expansive {
    @include able.ModalContent(Expansive);
  }
}
