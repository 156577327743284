@use "../var" with ( $include-breakpointLG: false );@use '..' as able;

.able-MessageInstruction {
  &--Read {
    @include able.MessageInstruction(Read);
  }

  &--Play {
    @include able.MessageInstruction(Play);
  }

  &--Ask {
    @include able.MessageInstruction(Ask);
  }

  &--Record {
    @include able.MessageInstruction(Record);
  }

  &--Send {
    @include able.MessageInstruction(Send);
  }
}
