@media screen and (min-width: 64rem) {
  .contact-details-business-registration [class*=able-MessageSection] {
    max-width: 100%;
    min-width: 33.33%;
  }
}
.contact-details-business-registration .able-ActionButton--low-emphasis.boost-able-button {
  width: fit-content;
}
.contact-details-business-registration .abn-acn-success-container {
  margin-bottom: 16px;
}
.contact-details-business-registration .abn-acn-success-container .able-ActionButton {
  margin-bottom: 8px;
}
.contact-details-business-registration .abn-acn-success-container .able-MessageInline--Success {
  margin-bottom: 24px;
}
.contact-details-business-registration .abn-acn-success-container .able-text-style--HeadingC {
  margin-bottom: 8px;
}
.contact-details-business-registration .abn-acn-success-container #business-name-select-container {
  margin-top: 16px;
}
.contact-details-business-registration .abn-acn-success-container #business-name-select-container .able-text-style--FinePrint {
  color: #707070;
}
.contact-details-business-registration .abn-acn-success-container #business-name-select-container #businessName-error-description svg {
  margin-left: 0;
}
.contact-details-business-registration .verifyABN-button {
  width: 100%;
}
.contact-details-business-registration .error-msg {
  margin-top: 0.3rem;
}
