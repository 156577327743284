.add-more-button button,
.add-more-button button:focus {
  border: none;
  padding: 0!important;
  cursor: pointer;
  outline: none !important;
  background: #dcdcdc;
}
label#checkbox-label {
  border: none !important;
}
label#checkbox-label.has-error {
  border: none !important;
}
input[type="checkbox"]#addMore + label::before {
  background-color: #cdcdcd;
  color: #f8f8f8 !important;
  content: '\002b';
}
input[type="checkbox"]#addMore + label.checked::after {
  content: ' ' !important;
}
.select-identity .tppa-select select {
  width: 100% !important;
  margin-bottom: 0px;
}
@media only screen and (max-width: 420px) {
  .select-identity .tppa-select select {
    word-wrap: break-word;
    /* IE*/
    white-space: -moz-pre-wrap;
    /* Firefox */
    white-space: pre-wrap;
    /* other browsers */
    display: inline-block;
    height: fit-content;
  }
}
.select-identity .tppa-select .td-icon {
  top: -30px;
  left: -20px;
  float: right !important;
}
.select-identity .btn {
  margin-top: 10px !important;
}
.select-identity .error-msg {
  margin-top: 10px;
}
.id-page-terms-wrap {
  border: 1px solid #d2d2d2;
  padding: 10px 20px;
  border-radius: 3px;
  margin-top: 40px;
  margin-bottom: 10px;
  background-color: var(--white);
}
.id-page-terms-wrap.active {
  border: 1px solid #FF7F3F;
  background-color: #ffe7cc;
  padding-bottom: 40px;
}
.id-page-terms-wrap label {
  color: #414141;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.15px;
  line-height: 24px;
}
.inclusion-item {
  height: 2.24%;
  width: 100%;
  color: #7C7C7C;
  font-family: Montserrat;
  font-size: 14px;
  letter-spacing: 0.18px;
  line-height: 20px;
}
.esim-wrapper .form-group {
  margin-bottom: 0px;
}
.esim-wrapper .subTitle {
  width: auto;
  height: auto;
}
.esim-wrapper .checkbox-label {
  width: auto;
}
.esim-wrapper .checkbox-label p {
  margin: 0px !important;
}
@media screen and (min-width: 768px) {
  .esim-wrapper .subTitle {
    height: 15px;
    word-break: break-all;
  }
  .esim-wrapper .checkbox-label {
    width: 690px;
    word-break: break-all;
  }
}
.esim-wrapper .tppa-select + .next {
  margin-top: 50px;
}
.esim-wrapper .next {
  word-break: break-all;
}
.esim-wrapper .next .button-as-link {
  margin-top: 10px;
}
.id-page-heading {
  margin-top: 16px;
}
.id-page-main-form {
  margin-top: 32px;
}
.id-page-main-form > .form-element {
  max-width: 420px;
}
.id-page-main-form .able-TextField.onlyLabel input {
  display: none !important;
}
.id-page-main-form .dateOfBirth .form-element {
  margin: 0 !important;
}
.id-page-main-form.isvalidated .chev-down {
  display: none;
}
.id-page-main-form.isvalidated .no-middle-name {
  margin-top: 8px;
  display: none;
}
.id-page-main-form.isvalidated .onlyLabel.no-middle-name {
  display: block;
  top: -5px;
  margin-top: 8px;
  margin-bottom: 32px;
}
.id-page-main-form.isvalidated .text-grey-dark {
  margin-top: 0 !important;
  margin-bottom: 8px !important;
}
.id-page-main-form.isvalidated #birthDate-description {
  display: none;
}
.id-page-main-form.isvalidated .form-element {
  margin: 0 0 32px 0;
  min-height: 30px !important;
}
.id-page-main-form.isvalidated .form-element .styled-checkbox + label {
  padding-bottom: 15px;
}
.id-page-main-form.isvalidated select {
  border: 0 !important;
}
.id-page-main-form.isvalidated input {
  border: 0 !important;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}
.id-page-main-form.isvalidated .id-page-terms-wrap {
  border: 0;
  background-color: transparent;
  padding: 0;
  margin-top: 0px;
}
.id-page-main-form.isvalidated .id-page-form-subheader {
  margin-top: 48px;
  margin-bottom: 32px;
}
.id-page-main-form.isvalidated .dateOfBirth {
  margin-top: 0 !important;
}
.id-page-form-subheader {
  margin-bottom: 8px;
  margin-top: 80px;
}
.btn-input-control-id {
  position: relative;
  float: left;
  width: 33% !important;
  min-height: 1px;
  margin-top: -15px;
  height: 48px;
}
@media only screen and (max-width: 420px) {
  .btn-input-control-id {
    width: 100% !important;
    margin-top: 0px !important;
  }
}
.btn-input-control-id .personal {
  border-radius: 4px 0px 0px 4px;
}
@media only screen and (max-width: 420px) {
  .btn-input-control-id .personal {
    border-radius: 4px 4px 0px 0px;
  }
}
.btn-input-control-id .business {
  border-radius: 0px 4px 4px 0px;
}
@media only screen and (max-width: 420px) {
  .btn-input-control-id .business {
    border-radius: 0px 0px 4px 4px;
  }
}
.btn-input-control-id .soleTrader {
  border-radius: 0px;
}
@media only screen and (max-width: 420px) {
  .btn-input-control-id .soleTrader {
    border-radius: 0px;
  }
}
.checkbox-label {
  min-height: 30px !important;
  padding-bottom: 40px;
}
.checkbox-label input {
  height: 0px !important;
}
.accept-tems-note {
  font-size: 12px;
  margin-top: 10px;
}
.id-check-consent {
  margin-top: 80px;
}
.success-message {
  display: flex;
  border: 1px solid #058B38;
  border-radius: 3px;
  background-color: #F2FFF3;
  margin: 25px 0px 35px 0px;
  overflow: hidden;
  word-wrap: break-word;
}
.success-message .success-message-box {
  background-color: #F2FFF3;
  color: #1C734E !important;
  padding-left: 20px;
  padding-top: 5px !important;
  padding-bottom: 15px;
  padding-right: 25px;
}
.success-message .success-message-box .success-message-title {
  font-size: 16px;
  line-height: 22px;
  margin: 10px 0px 0px 0px!important;
}
.success-message .td-icon-sm {
  top: 13px;
  position: relative;
  left: 12px;
  font-size: 25px !important;
}
.success-message .icon-round-tick {
  color: #1C734E !important;
}
.id-page-banner-img {
  margin-top: 200px;
}
label[for="familyName"] {
  margin-top: 0px !important;
}
.next .validation-error {
  margin-top: 15px;
}
.id-page .alert-bar {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-left: 4px solid #FF4300;
  margin-bottom: 12px;
  padding: 0rem !important;
}
.id-page #info-txt [class*=able-MessageSection] {
  max-width: 100%;
  width: 100%;
}
.id-page .idv-link-text {
  text-decoration: underline !important;
  position: relative;
  top: 8px;
  left: 0;
  font-size: 14px;
  color: #DB3A00;
  font-family: Montserrat;
  font-weight: bold;
  letter-spacing: 0.18px;
  line-height: 21px;
}
.id-page .idv-image {
  margin-top: 200px;
}
@media (min-width: 68rem) {
  .id-page .idv-image {
    display: inline-block;
  }
}
.id-page .verify-btn {
  width: 100%;
  margin-bottom: 40px;
  margin-top: 32px;
}
.id-page .checkbox-label-txt {
  font-weight: 300;
  letter-spacing: 0.15px;
}
.id-page .idv-error-subheading {
  margin-top: 40px;
  margin-bottom: 15px;
}
.id-page .idv-doc-listing {
  position: relative;
  padding-left: 30px;
  margin-bottom: 40px;
}
.id-page .idv-doc-listing .idv-tick-icon {
  position: absolute;
  left: 5px;
}
@media only screen and (min-width: 600px) {
  .id-page .card-info {
    padding-right: 95px;
    margin-top: 10px;
  }
}
#errorText [class*=able-MessageSection] {
  max-width: 100%;
}
.edit-id-details #edit-message-section [class*=able-MessageSection] {
  max-width: 100%;
  margin-bottom: 32px;
}
.edit-id-details .edit-id-details-data {
  margin: 16px 0;
}
.edit-id-details .edit-id-details-data .edit-id-details-data-value {
  margin: 0.75rem 0 0 0;
}
.edit-id-details .edit-id-details-data .updateDateBtn {
  padding-bottom: 0 !important;
}
#info-message-section [class*=able-MessageSection] {
  max-width: 100%;
  margin-top: 8px;
}
.closable-info-toast {
  border-left: 3px solid #0eaa28;
}
.closable-info-toast p {
  display: flex;
}
.closable-info-toast .close-svg {
  margin-right: 0;
  cursor: pointer;
}
.closable-info-toast .tick-svg {
  background: #0eaa28;
  margin-right: 10px;
  border-radius: 50%;
  padding: 2px;
  height: 1.2rem;
  width: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.closable-info-toast .tick-svg svg {
  height: 1rem;
  width: 1rem;
}
.closable-info-toast .tick-svg path {
  fill: #fff;
}
.idv-consent-box label span {
  padding-top: 0 !important;
}
