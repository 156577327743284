.able-Checkbox input:checked ~ label::after,
.able-Checkbox--Comfortable input:checked ~ label::after {
  background-color: #ff4300 !important;
  box-shadow: 0 0 0 0.125rem rgba(255, 67, 0, 0.16) !important;
}
.able-Checkbox input:checked ~ label:hover::before,
.able-Checkbox--Comfortable input:checked ~ label:hover::before,
.able-Checkbox input:checked:hover ~ label::before,
.able-Checkbox--Comfortable input:checked:hover ~ label::before {
  background-color: rgba(255, 67, 0, 0.1);
}
.able-Checkbox input:checked ~ label:active::before,
.able-Checkbox--Comfortable input:checked ~ label:active::before,
.able-Checkbox input:checked:active ~ label::before,
.able-Checkbox--Comfortable input:checked:active ~ label::before {
  background-color: rgba(255, 67, 0, 0.16);
}
.able-Checkbox input:focus ~ label::after,
.able-Checkbox--Comfortable input:focus ~ label::after,
.able-Checkbox input:checked:focus ~ label::after,
.able-Checkbox--Comfortable input:checked:focus ~ label::after,
.able-Checkbox input:checked:active ~ label::after,
.able-Checkbox--Comfortable input:checked:active ~ label::after {
  box-shadow: 0 0 0 0.1875rem #ff4300 !important;
  border: none;
}
.able-Checkbox input,
.able-Checkbox--Comfortable input {
  opacity: 0 !important;
}
.able-Checkbox--Comfortable input:hover ~ label::before,
.able-Checkbox--Comfortable--Comfortable input:hover ~ label::before {
  background-color: rgba(255, 67, 0, 0.16) !important;
  box-shadow: 0 0 0 0.0625rem #ff4300 !important;
}
.able-Checkbox--Comfortable input:hover ~ label::after,
.able-Checkbox--Comfortable--Comfortable input:hover ~ label::after {
  border: 0.0625rem solid #ff4300 !important;
}
.able-Checkbox--Comfortable input:focus ~ label::before,
.able-Checkbox--Comfortable--Comfortable input:focus ~ label::before {
  box-shadow: 0 0 0 0.1875rem #ff4300 !important;
  background-color: rgba(255, 67, 0, 0.16) !important;
}
.able-Checkbox--Comfortable input:checked:focus ~ label:before,
.able-Checkbox--Comfortable--Comfortable input:checked:focus ~ label:before,
.able-Checkbox--Comfortable input:checked ~ label::before,
.able-Checkbox--Comfortable--Comfortable input:checked ~ label::before {
  box-shadow: 0 0 0 0.1875rem #ff4300 !important;
}
