.confirm-ar-modal header [class$=__picto] {
  background-color: #0064d224;
}
.confirm-ar-modal header [class$=__picto] > svg {
  margin-left: 0;
  width: 2rem;
  height: 2rem;
}
.confirm-ar-modal header [class$=__picto] > svg use,
.confirm-ar-modal header [class$=__picto] > svg path,
.confirm-ar-modal header [class$=__picto] > svg rect {
  fill: #0064d2;
}
