.boost-header {
  width: 100%;
  z-index: 100;
  background-color: #282828;
  margin: 0;
  height: 40px;
}
.boost-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  margin-left: 29px;
  margin-right: 16px;
}
.boost-header-container .boost-logo svg {
  height: 44px;
  width: 77px;
}
.sign-out {
  color: #FF4300 !important;
  display: flex;
  align-items: center;
  height: 100%;
}
.icon-sign-out {
  width: 22px;
  height: 22px;
  margin-right: 5px;
  stroke: #FF4300 !important;
}
@media only screen and (min-width: 48rem) {
  .boost-header {
    height: 60px;
  }
  .boost-header-container {
    margin-left: 116px;
    margin-right: 100px;
  }
  .boost-header-container .boost-logo svg {
    height: 44px;
    width: 120px;
  }
}
