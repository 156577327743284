@use "../var" with ( $include-breakpointLG: false );@use '..' as able;

.able-text-style {
  &--HeadingDisplay {
    @include able.TextStyle('HeadingDisplay');
  }

  &--HeadingDisplayCenter {
    @include able.TextStyle('HeadingDisplay', 'Center');
  }

  &--HeadingDisplayInverted {
    @include able.TextStyle('HeadingDisplay', 'Inverted');
  }

  &--HeadingDisplayInvertedCenter {
    @include able.TextStyle('HeadingDisplay', 'Inverted', 'Center');
  }

  &--HeadingA {
    @include able.TextStyle('HeadingA');
  }

  &--HeadingACenter {
    @include able.TextStyle('HeadingA', 'Center');
  }

  &--HeadingAInverted {
    @include able.TextStyle('HeadingA', 'Inverted');
  }

  &--HeadingAInvertedCenter {
    @include able.TextStyle('HeadingA', 'Inverted', 'Center');
  }

  &--HeadingB {
    @include able.TextStyle('HeadingB');
  }

  &--HeadingBCenter {
    @include able.TextStyle('HeadingB', 'Center');
  }

  &--HeadingC {
    @include able.TextStyle('HeadingC');
  }

  &--HeadingCCenter {
    @include able.TextStyle('HeadingC', 'Center');
  }

  &--HeadingD {
    @include able.TextStyle('HeadingD');
  }

  &--HeadingDCenter {
    @include able.TextStyle('HeadingD', 'Center');
  }

  &--Subheadline {
    @include able.TextStyle('Subheadline');
  }

  &--SubheadlineInverted {
    @include able.TextStyle('Subheadline', 'Inverted');
  }

  &--TextDisplay {
    @include able.TextStyle('TextDisplay');
  }

  &--TextLeading {
    @include able.TextStyle('TextLeading');
  }

  &--TextLeadingCenter {
    @include able.TextStyle('TextLeading', 'Center');
  }

  &--TextLeadingInverted {
    @include able.TextStyle('TextLeading', 'Inverted');
  }

  &--TextLeadingInvertedCenter {
    @include able.TextStyle('TextLeading', 'Inverted', 'Center');
  }

  &--TextBigBodyLong {
    @include able.TextStyle('TextBigBodyLong');
  }

  &--TextBigBodyLong--with-list {
    @include able.TextStyle('TextBigBodyLong', $include-lists: true);
  }

  &--TextBigBodyShort {
    @include able.TextStyle('TextBigBodyShort');
  }

  &--TextBigBodyShort--with-list {
    @include able.TextStyle('TextBigBodyShort', $include-lists: true);
  }

  &--TextBigBodyShortCenter {
    @include able.TextStyle('TextBigBodyShort', 'Center');
  }

  &--TextBigBodyShortInverted {
    @include able.TextStyle('TextBigBodyShort', 'Inverted');
  }

  &--TextBigBodyShortInvertedCenter {
    @include able.TextStyle('TextBigBodyShort', 'Inverted', 'Center');
  }

  &--TextBodyLong {
    @include able.TextStyle('TextBodyLong');
  }

  &--TextBodyLong {
    @include able.TextStyle('TextBodyLong');
  }

  &--TextBodyLong--with-list {
    @include able.TextStyle('TextBodyLong', $include-lists: true);
  }

  &--TextBodyShort {
    @include able.TextStyle('TextBodyShort');
  }

  &--TextBodyShort--with-list {
    @include able.TextStyle('TextBodyShort', $include-lists: true);
  }

  &--TextBodyShortCenter {
    @include able.TextStyle('TextBodyShort', 'Center');
  }

  &--TextBodyShortInverted {
    @include able.TextStyle('TextBodyShort', 'Inverted');
  }

  &--TextInvertedCenter {
    @include able.TextStyle('TextBodyShort', 'Inverted', 'Center');
  }

  &--FinePrint {
    @include able.TextStyle('FinePrint');
  }

  &--FinePrintCenter {
    @include able.TextStyle('FinePrint', 'Center');
  }

  &--FinePrintInverted {
    @include able.TextStyle('FinePrint', 'Inverted');
  }

  &--FinePrintInvertedCenter {
    @include able.TextStyle('FinePrint', 'Inverted', 'Center');
  }

  &--FinePrintSubtle {
    @include able.TextStyle('FinePrint', 'Subtle');
  }

  &--FinePrintSubtleCenter {
    @include able.TextStyle('FinePrint', 'Subtle', 'Center');
  }

  &--Label {
    @include able.TextStyle('Label');
  }
}
