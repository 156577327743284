@use "../var" with ( $include-breakpointLG: false );@use '..' as able;

.able-Spacing {
  display: block;
}

/// COLUMNS WITH BREAKPOINTS
/// to-do pull this from grid/atomic in @able/web
@function breakpoints() {
  @if able.$include-breakpointLG {
    @return (
      vxs: viewportXS,
      vsm: viewportSM,
      vmd: viewportMD,
      vlg: viewportLG,
      bsm: breakpointSM,
      bmd: breakpointMD,
      blg: breakpointLG
    );
  } @else {
    @return (
      vxs: viewportXS,
      vsm: viewportSM,
      vmd: viewportMD,
      bsm: breakpointSM,
      bmd: breakpointMD
    );
  }
}

$_breakpoints: breakpoints();

@each $key, $val in able.$spacing-names {
  .able-Spacing--top--#{$key} {
    padding-top: able.spacing($key);
  }
  .able-Spacing--bottom--#{$key} {
    padding-bottom: able.spacing($key);
  }
  .able-Spacing--left--#{$key} {
    padding-left: able.spacing($key);
  }
  .able-Spacing--right--#{$key} {
    padding-right: able.spacing($key);
  }
}

@each $_breakpoint, $_alias in $_breakpoints {
  @each $key, $val in able.$spacing-names {
    @include able.mediaQuery(#{$_alias}) {
      .able-Spacing--top--#{$key}--#{$_breakpoint} {
        padding-top: able.spacing($key);
      }
      .able-Spacing--bottom--#{$key}--#{$_breakpoint} {
        padding-bottom: able.spacing($key);
      }
      .able-Spacing--left--#{$key}--#{$_breakpoint} {
        padding-left: able.spacing($key);
      }
      .able-Spacing--right--#{$key}--#{$_breakpoint} {
        padding-right: able.spacing($key);
      }
    }
  }
}

//spacing aliases
$pattern-class: able-Spacing;
$spacing-types: margin, padding;
$spacing-positions: top, bottom;
$spacing-aliases: Compact, Comfortable, Expansive, Associated, Unassociated;

@each $spacing-type in $spacing-types {
  @each $spacing-position in $spacing-positions {
    @each $spacing-alias in $spacing-aliases {
      .#{$pattern-class}--#{$spacing-alias}--#{$spacing-type}-#{$spacing-position} {
        @include able.generateVerticalSpacing(
          $spacing-type,
          $spacing-position,
          $alias: spacing#{$spacing-alias}
        );
      }
    }
  }
}
